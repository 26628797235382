'use strict'

###*
 # @ngdoc service
 # @name mundoSettings.factory:MundoSettings

 # @description

###
angular
  .module 'mundoSettings'
  .factory 'MundoSettings', [
    'Restangular'
    '$q'
    '$rootScope'
    (Restangular, $q, $rootScope) ->
      MundoSettingsBase = {}
      MundoSettingsBase.transport = Restangular.service('settings')

      MundoSettingsBase.set = (code, value, options = {}) ->
        defaults =
          tenant: true
          tag: null
        options = angular.merge {}, defaults, options

        if not angular.isString value
          value = JSON.stringify value

        parameters =
          code: code
          value: value
          tag: options.tag

        if options.tenant
          parameters.tenant = $rootScope.user.activeUserContext.tenant.id

        return $q (resolve, reject) =>
          @transport.post parameters
          .then (result) ->
            resolve(result)
          , (error) =>
            @get code,
              value: false
            .then (result) =>
              result.remove().then () =>
                @set code, value, options
                .then (res) ->
                  resolve(res)
                , (err) ->
                  reject(err)
            , (err) ->
              reject(err)

      MundoSettingsBase.get = (code, options = {}) ->
        defaults =
          value: true
        options = angular.merge {}, defaults, options

        return $q (resolve, reject) =>
          @transport.getList
            'filter[]': "code,EQ #{code}"
            'limit': 1
          .then (results) ->
            if results.length
              result = results[0]

              try
                result.value = angular.fromJson result.value
              catch
                # Do nothing
                result.value

              if options.value
                result = result.value

              resolve(result)
            else
              reject()
          , (error) ->
            reject(error)

      MundoSettingsBase.remove = (code, options = {}) ->
        return $q (resolve, reject) =>
          @get code,
            value: false
          .then (result) ->
            result.remove().then () ->
              resolve()
            , () ->
              reject()
          , () ->
            resolve()

      MundoSettingsBase
  ]
